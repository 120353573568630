import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MyImage = makeShortcode("MyImage");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "move-the-pointer-to-the-left-of-the-page-so-the-small-cross-shows-up-click-and-add-a-live-code-view-widget",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#move-the-pointer-to-the-left-of-the-page-so-the-small-cross-shows-up-click-and-add-a-live-code-view-widget",
        "aria-label": "move the pointer to the left of the page so the small cross shows up click and add a live code view widget permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Move the pointer to the left of the page, so the small cross shows up. Click and add a `}<em parentName="h3">{`Live Code View`}</em>{` widget.`}</h3>
    <MyImage src="live-code-view-0.png" alt="Creating Notes 4" className="w-75" mdxType="MyImage" />
    <h3 {...{
      "id": "copy-the-token-to-your-clipboard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#copy-the-token-to-your-clipboard",
        "aria-label": "copy the token to your clipboard permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Copy the token to your clipboard`}</h3>
    <MyImage src="live-code-view-1.png" alt="New Live Code View" className="w-75" mdxType="MyImage" />
    <p>{`If you hit `}<em parentName="p">{`Create`}</em>{` at this point, the Live Code View is created but it displays a message about the token being not found.`}</p>
    <p>{`It doesn't matter.`}</p>
    <MyImage src="live-code-view-1b.png" alt="New Live Code View" className="w-75" mdxType="MyImage" />
    <h3 {...{
      "id": "insert-the-token-in-your-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#insert-the-token-in-your-file",
        "aria-label": "insert the token in your file permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Insert the token in your file`}</h3>
    <p>{`Switch to your favorite IDE.`}</p>
    <p>{`Locate the code block you want to embed in your note and paste the token into a comment.`}</p>
    <p>{`Save the file.`}</p>
    <MyImage src="live-code-view-2.png" alt="Live Code View" mdxType="MyImage" />
    <p>{`Back to CodeStory, the Live Code View should now be connected!`}</p>
    <MyImage src="live-code-view-3.png" alt="Live Code View" mdxType="MyImage" />
    <p>{`If it's not, check that your code sources are `}<Link to="/documentation/code-sources" mdxType="Link">{`properly configured`}</Link>{`.
You should check that the file:`}</p>
    <ul>
      <li parentName="ul">{`has an extension that's one the supported file types,`}</li>
      <li parentName="ul">{`has a path that belongs to a defined source,`}</li>
      <li parentName="ul">{`is not excluded by glob and specific exclusions.`}</li>
    </ul>
    <h3 {...{
      "id": "adjust-the-live-code-view",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#adjust-the-live-code-view",
        "aria-label": "adjust the live code view permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Adjust the Live Code View`}</h3>
    <p>{`Use the inspector to adjust the number of lines that should be displayed.`}</p>
    <MyImage src="live-code-view-4.png" alt="Live Code View" mdxType="MyImage" />
    <p>{`Doing so also automatically confirms the Live Code View.`}</p>
    <p><em parentName="p">{`New in CodeStory 2021.1`}</em>{` :
Check out `}<Link to="/blog/2021/smart-contexts" mdxType="Link">{`Smart contexts`}</Link>{`.`}</p>
    <h3 {...{
      "id": "repeat-with-more-live-code-views-and-minimal-guidance",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#repeat-with-more-live-code-views-and-minimal-guidance",
        "aria-label": "repeat with more live code views and minimal guidance permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Repeat with more Live Code Views and minimal guidance`}</h3>
    <p>{`Here is an example of what the final note could look like. As you can see, no need to be verbose!`}</p>
    <MyImage src="live-code-view-5.png" alt="Live Code View" mdxType="MyImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      